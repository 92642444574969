import {
  useActiveProfessionsListBaseInfo,
} from '@/controllers/profession/profession.hooks/useActiveProfessionsListBaseInfo';
import { LearningTracksShortInfo } from '../OtherLearingTracksBlock.typedefs';

interface UseOtherProfessions {
  (
    options: {
      exceptSlug?: string;
      skip?: boolean;
    }
  ): LearningTracksShortInfo[];
}

export const useOtherProfessions: UseOtherProfessions = (options) => {
  const {
    exceptSlug,
    skip,
  } = options;

  const [professions] = useActiveProfessionsListBaseInfo({ skip });

  if (exceptSlug) {
    return professions.filter((profession) => (
      profession.slug !== exceptSlug
    ));
  }

  return professions;
};
