import React, { memo } from 'react';
import { ConsultationCard } from '@/components/homepageLanding/ProfessionsListSection/components/ConsultationCard';
import { ProfessionCard } from '@/components/homepageLanding/ProfessionsListSection/components/ProfessionCard';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { section } from '@/components/ui/section';
import {
  useGetProfessionsListWithCourses,
} from '@/controllers/profession/profession.hooks/useGetProfessionsListWithCourses';
import { CourseType } from '@/controllers/graphql/generated';
import { IconImage } from '@/components/ui/Image/IconImage';
import landingVerificationsReclameAQUIS3Key from '@/images/generated/landing.verifications.ReclameAQUI.s3Key';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { CardMode } from './components/ProfessionCard/ProfessionCard.typedefs';
import styles from './ProfessionsListSection.module.scss';

interface Props {
  id?: string;
  cardMode: CardMode;
  courseTypes: CourseType[];
  useCTXHref?: boolean;
  singleModeButtonText?: string;
}

export const ProfessionsListSection = memo<Props>((props) => {
  const {
    id,
    courseTypes,
    cardMode,
    useCTXHref,
    singleModeButtonText,
  } = props;

  const { subDomain } = useSubDomainContext();

  const { t } = useTranslation([I18N_CODES.home, I18N_CODES.cta]);

  const [professionsWithCourses] = useGetProfessionsListWithCourses(
    { courseTypes },
  );

  if (!professionsWithCourses.length) {
    return null;
  }

  const isFullWidthLastCard = professionsWithCourses.length % 2 === 0;

  return (
    <section
      id={id}
      className={cn(
        'grid-container',
        { [section.scrollSection]: Boolean(id) },
      )}
    >
      <div className='grid-x grid-margin-x align-center'>
        <div className={cn(
          'cell large-10',
          'mb-72 small-mb-40',
          'flex-container align-center-middle',
          'gap-32 small-gap-24',
          'flex-dir-column medium-flex-dir-row',
        )}
        >
          <h2 className={typography.landingH2}>
            {t(`${I18N_CODES.home}:our_courses_title`)}
          </h2>

          {subDomain === 'br' && (
            <IconImage
              imageData={landingVerificationsReclameAQUIS3Key}
              width={158}
              height={56}
            />
          )}
        </div>

        <div className='cell large-10'>
          <div
            className={cn(
              styles.cardsWrapper,
              { [styles.fullWidthLastCard]: isFullWidthLastCard },
            )}
          >
            {professionsWithCourses.map((profession) => (
              <ProfessionCard
                key={profession.slug}
                profession={profession}
                cardMode={cardMode}
                useCTXHref={useCTXHref}
                singleModeButtonText={singleModeButtonText}
              />
            ))}

            <ConsultationCard
              isFullWidthCard={isFullWidthLastCard}
            />
          </div>
        </div>
      </div>
    </section>
  );
});
