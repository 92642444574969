import { CourseType } from '@/controllers/graphql/generated';
import { SubDomains } from '@/constants/subdomains';
import fullstackCoverPersona from '@/images/generated/professionLanding.coverPersonas.fullstackCoverPersona.s3Key';
import dataAnalyticsCoverPersona from '@/images/generated/professionLanding.coverPersonas.dataAnalystCoverPersona.s3Key';
import qaCoverPersona from '@/images/generated/professionLanding.coverPersonas.qaCoverPersona.s3Key';
import pythonCoverPersona from '@/images/generated/professionLanding.coverPersonas.pythonCoverPersona.s3Key';
import devopsCoverPersona from '@/images/generated/professionLanding.coverPersonas.devopsCoverPersona.s3Key';
import digitalMarketingCoverPersona from '@/images/generated/professionLanding.coverPersonas.digitalMarketingCoverPersona.s3Key';
import javaCoverPersona from '@/images/generated/professionLanding.coverPersonas.javaCoverPersona.s3Key';
import recruitmentCoverPersona from '@/images/generated/professionLanding.coverPersonas.recruiterCoverPersona.s3Key';
import uiuxCoverPersona from '@/images/generated/professionLanding.coverPersonas.uiuxCoverPersona.s3Key';
import frontendCoverPersona from '@/images/generated/professionLanding.professionCoverMale.s3Key';
import { ProfessionSlugs } from '@/components/professionLanding/ProfessionLanding.typedefs';
import { PageType } from '@/components/landing/Promotions/promotions.typedefs';
import { CourseShortInfo } from '../courseLanding/CourseLandingPageModule/CourseLandingPage.typedefs';

export class ProfessionLandingHelpers {
  static getTrackSlugByProfessionSlug(professionSlug: string) {
    const domainPattern = Object.values(SubDomains).join('|');

    return professionSlug.replace(new RegExp(`-(${domainPattern})$`), '');
  }

  static getActiveCourse(
    courses: CourseShortInfo[] | null | undefined,
  ) {
    const postpaidCourse = this.getCourseByType(
      courses,
      CourseType.Postpaid,
    );
    const prepaidCourse = this.getCourseByType(
      courses,
      CourseType.Prepaid,
    );

    return (postpaidCourse || prepaidCourse) as CourseShortInfo;
  }

  static getCourseByType(
    courses: CourseShortInfo[] | null | undefined,
    courseType: CourseType,
  ) {
    return courses?.find(({ type }) => type === courseType);
  }

  static getProfessionLandingCoverImageData(professionSlug: string) {
    const trackSlug = this.getTrackSlugByProfessionSlug(professionSlug);

    switch (trackSlug) {
      case ProfessionSlugs.Fullstack: {
        return fullstackCoverPersona;
      }

      case ProfessionSlugs.DataAnalytics: {
        return dataAnalyticsCoverPersona;
      }

      case ProfessionSlugs.Qa: {
        return qaCoverPersona;
      }

      case ProfessionSlugs.Python: {
        return pythonCoverPersona;
      }

      case ProfessionSlugs.DevOps: {
        return devopsCoverPersona;
      }

      case ProfessionSlugs.DigitalMarketing: {
        return digitalMarketingCoverPersona;
      }

      case ProfessionSlugs.Java: {
        return javaCoverPersona;
      }

      case ProfessionSlugs.Recruitment: {
        return recruitmentCoverPersona;
      }

      case ProfessionSlugs.UiUx: {
        return uiuxCoverPersona;
      }

      case ProfessionSlugs.Frontend:
      default: {
        return frontendCoverPersona;
      }
    }
  }

  // 🚨 Temporary hardcoded link for UI/UX 🚨
  static getAdditionalCardLinkProps(options: {
    professionSlug: string;
    pageType?: PageType;
  }) {
    const { professionSlug, pageType } = options;

    const isCoursesPage = pageType === PageType.COURSES;
    const isDesignProfession = professionSlug === ProfessionSlugs.UiUx;
    const shouldShowHardcodedLink = isCoursesPage && isDesignProfession;

    return shouldShowHardcodedLink
      ? { form_id_modifier: 'merged' }
      : {};
  }
}
